import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the datalabels plugin
import { useTheme, Box, Heading } from '@chakra-ui/react';

// Register ChartJS elements and the datalabels plugin
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const CallDirectionChart = ({ directionData }) => {
  const theme = useTheme();  // Access Chakra UI theme
  const [data, setData] = useState({ inbound: 0, outbound: 0 });

  const fetchData = async () => {
    setData(directionData);
  }
  useEffect(() => {
    fetchData()
  }, []);

  const total = data.inbound + data.outbound; // Calculate total for percentage

  const chartData = {
    labels: ['Inbound', 'Outbound'],
    datasets: [
      {
        label: 'Cost',
        data: [data.inbound, data.outbound],
        backgroundColor: [theme.colors.blue[300], theme.colors.teal[300]],  // Use Chakra colors
        hoverBackgroundColor: [theme.colors.blue[400], theme.colors.teal[400]],
        borderColor: [theme.colors.blue[500], theme.colors.teal[500]],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: theme.colors.gray[700], // Legend text color
        },
      },
      datalabels: {
        formatter: (value, context) => {
          const percentage = ((value / total) * 100).toFixed(2) + '%';
          return percentage; // Show percentage on each slice
        },
        color: 'white', // Label text color on the slices
        font: {
          size: 14,
          weight: 'bold',
        },
      },
    },
  };

  return (
    <Box bg="white" p={6} borderRadius="md" shadow="md">
      <Heading size="md" mb={4}>Inbound vs Outbound Calls</Heading>
      <Pie data={chartData} options={options} />
    </Box>
  );
};

export default CallDirectionChart;
