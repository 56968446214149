import React, { useState,useEffect } from 'react';
import {
  Box, Button, Input, Heading, VStack, FormControl, FormLabel, useToast, Flex, Divider,
  Tr, Table, Th, Td, Thead, Tbody
} from '@chakra-ui/react';
import Navigation from '../components/Navigation';
import axios from 'axios';

const AdminCreateUserPage = () => {
  const [formData, setFormData] = useState({ name: '', email: '', password: '' });
  const [csvFile, setCsvFile] = useState(null);
  const toast = useToast();
  const [isAdmin, setIsAdmin] = useState(null);  // null initially (unknown status)
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        // Make API call to check if user is authenticated
        const response = await axios.get('/api/checkIsAdmin');
        setIsAdmin(response.data.isAdmin);
      } catch (error) {
        console.error('Error checking session:', error);
        setIsAdmin(false);  // Assume not authenticated if there's an error
      } finally {
      }
    };

    checkAuthentication();  // Check authentication when component mounts
  }, []);

  const formatDateTime = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
 };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      await axios.post('/api/admin/create-user', formData);
      toast({ title: 'User created successfully!', status: 'success' });
    } catch (error) {
      toast({ title: 'Failed to create user', status: 'error' });
    }
  };

  const listUsers = async () => {
    try {
        const response = await axios.get('/api/admin/users');
        if(response.data?.users) {
            setUsers(response.data.users);
        }else {
            toast({ 
                title: 'Failed to retrieve users', 
                status: 'error' });
        }
      } catch (error) {
        toast({ title: 'Failed to retrieve users', status: 'error' });
      }
  }

  const createCollection = async () => {
    try {
        const response = await axios.post('/api/admin/create-collection', formData);
        if(response.data?.success) {
            toast({ 
                title: 'Collection created successfully!', 
                description: response.data.message,
                status: 'success' 
            });
        }else {
            toast({ 
                title: 'Failed to create collection', 
                description: response.data.message,
                status: 'error' 
            });
        }
    } catch (error) {
        toast({ title: 'Failed to create collection', status: 'error' });
    }
  };

  const deleteUser = async(userId) => {
    try {
        const response = await axios.delete(`/api/admin/delete-user/${userId}`);
        if(response.data?.success) {
            await listUsers();
            toast({ title: 'User deleted successfully!', status: 'success' });
        }else {
            toast({ title: 'Failed to delete user', status: 'error' });
        }
      } catch (error) {
        toast({ title: 'Failed to delete user', status: 'error' });
      }
  };

  const resetUserPassword = async(userId) => {
    try {
        const response = await axios.post('/api/admin/reset-password', {userId});
        if(response.data?.success) {
            toast({ title: 'Password reset successfully!', status: 'success' });
        }else {
            toast({ title: 'Failed to reset user password', status: 'error' });
        }
      } catch (error) {
        toast({ title: 'Failed to reset user password', status: 'error' });
      }
  }

  const handleCsvUpload = async () => {
    const formData = new FormData();
    formData.append('file', csvFile);

    try {
      await axios.post('/api/admin/upload-users', formData);
      toast({ title: 'Users uploaded successfully!', status: 'success' });
    } catch (error) {
      toast({ title: 'Failed to upload users', status: 'error' });
    }
  };

  return !isAdmin?(<></>):( 
    <Flex>
      <Navigation />
      <Box p={8} flex="1">
        <Heading size="lg" mb={6}>Create User</Heading>
        <VStack spacing={4} align="stretch">
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              name="name"
              placeholder="Enter user name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              name="email"
              type="email"
              placeholder="Enter user email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input
              name="password"
              type="password"
              placeholder="Enter user password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Create Qdrant Collection</FormLabel>
            <Input type="text" name="collectionName" onChange={handleInputChange}></Input>
          </FormControl>

          <Button colorScheme="teal" onClick={handleSubmit}>Create User + Collection</Button>
          <Button colorScheme="teal" onClick={createCollection}>Create Collection</Button>
          <Divider my={6} />

          <FormControl>
            <FormLabel>Upload CSV</FormLabel>
            <Input
              type="file"
              onChange={(e) => setCsvFile(e.target.files[0])}
            />
          </FormControl>
          <Button colorScheme="blue" onClick={handleCsvUpload}>Upload Users</Button>

          <Button colorScheme="teal" onClick={listUsers}>Retrieve Users</Button>
          <Table variant="simple" size="lg">
          <Thead position="sticky" top={0} bg="gray.100" zIndex={1}>
            <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Role</Th>
                <Th>Created On</Th>
                <Th>Last Updated On</Th>
                <Th>Action</Th>
            </Tr>
            </Thead>
          <Tbody>
          {users.map((u) => (
            <Tr key={u._id}>
                <Td>{u.name}</Td>
                <Td>{u.email}</Td>
                <Td>{u.role}</Td>
                <Td>{formatDateTime(u.createdAt)}</Td>
                <Td>{formatDateTime(u.updatedAt)}</Td>
                <Td>
                    <Button colorScheme="red" onClick={() => deleteUser(u._id)}>Delete</Button>
                    <Button colorScheme="orange" onClick={() => resetUserPassword(u._id)}>Reset Password</Button>
                </Td>
            </Tr>
          ))}
          </Tbody>
            </Table>
          
        </VStack>
      </Box>
    </Flex>
  );
};

export default AdminCreateUserPage;
