import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Heading, Input, VStack, Text, FormControl, FormLabel, Grid } from '@chakra-ui/react';
import axios from 'axios';
import useCheckAuth from '../hooks/useCheckAuth';
import {Helmet} from "react-helmet";

const PasswordResetPage = () => {
    console.log('reached reset-password');
    useCheckAuth(true);
  const { token } = useParams(); // Get token from the URL params
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setError('');

    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      // Send the new password and token to the backend
      const response = await axios.post(`/api/reset-password/${token}`, { password });
      setMessage(response.data.message); // Display success message
      setTimeout(() => {
        navigate('/login'); // Redirect to login after 3 seconds
      }, 3000);
    } catch (err) {
      setError('Failed to reset password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Helmet>
      <title>Reset Your Password</title>
      <meta name="description" content="Reset Your Password" />
    </Helmet>
    <Box maxW="md" mx="auto" mt={8} p={6} borderWidth={1} borderRadius="lg">
      <Heading mb={6} textAlign="center">Reset Password</Heading>
      
      {/* Success message */}
      {message && <Text color="green.500" mb={4}>{message}</Text>}
      
      {/* Error message */}
      {error && <Text color="red.500" mb={4}>{error}</Text>}
      
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter new password"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Confirm New Password</FormLabel>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm new password"
            />
          </FormControl>

          <Button type="submit" width="full" isLoading={loading}>
            Reset Password
          </Button>
        </VStack>
      </form>
    </Box>
    </>
  );
};

export default PasswordResetPage;
