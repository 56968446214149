import React, { useState, useEffect } from 'react';
import {
  Box, Button, Input, Heading, VStack, FormControl, FormLabel, Select, Textarea, 
  Checkbox, Text, GridItem, Stepper, Step, StepIndicator, 
  StepStatus, StepTitle, StepDescription, StepSeparator, Flex, useToast,
  Table, Thead, Tbody, Tr, Th, Td
} from '@chakra-ui/react';
import Navigation from '../components/Navigation';
import useCheckAuth from '../hooks/useCheckAuth';
import {Helmet} from "react-helmet";
import axios from 'axios';


const ToolSetupPage = () => {
    useCheckAuth();

    const [tools, setTools] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editingToolId, setEditingToolId] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    url: '',
    async: false,
    params: [],
    security: {
      headers: {},
      queryParams: {},
      bodyParams: {},
      tokenType: ''
    }
  });
  const [formErrors, setFormErrors] = useState("")

  const toast = useToast();

  const steps = [
    { title: 'Basic Info', description: 'Enter the basic details' },
    { title: 'Parameters', description: 'Add parameters' },
    { title: 'Security Settings', description: 'Configure security' },
    { title: 'Review', description: 'Review and save' }
  ];

  const fetchTools = async () => {
    try {
      const response = await axios.get('/api/tools');
      setTools(response.data);
    } catch (error) {
      console.error('Error fetching tools:', error);
    }
  };

  // Fetch tools on component mount
  useEffect(() => {
    fetchTools();
  }, []);


  const resetForm = () => {
    setFormData({
        name: '',
        description: '',
        url: '',
        async: false,
        params: [],
        security: {
          headers: {},
          queryParams: {},
          bodyParams: {},
          tokenType: ''
        }
      });
    setCurrentStep(0);
  }

  const isValidUrl = (url) => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // Protocol
      '((([a-zA-Z0-9\\-\\.]+)\\.[a-zA-Z]{2,})|' + // Domain name
      'localhost|' + // Or localhost
      '\\d{1,3}(\\.\\d{1,3}){3})' + // Or IP (v4)
      '(\\:\\d+)?' + // Optional port
      '(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?' + // Path
      '(\\?[;&a-zA-Z0-9%_\\+.~#?&=]*)?' + // Query string
      '(\\#[-a-zA-Z0-9_]*)?$' // Fragment locator
    );
    return urlPattern.test(url);
  };

  const isValidToolName = (name) => {
    if(!name){
        return false;
    }
    const regex = /^[a-zA-Z0-9_-]{1,64}$/;
    return regex.test(name);
  }

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // Handle nested fields
    if (name.includes('.')) {
      const [mainKey, subKey] = name.split('.');
      setFormData((prevFormData) => ({
        ...prevFormData,
        [mainKey]: {
          ...prevFormData[mainKey],
          [subKey]: value,
        },
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleToolNameChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9_-]{1,64}$/;
    if(!regex.test(value) && value !== '') {
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            name: 'Must be a-z, A-Z, 0-9, or contain underscores and dashes, with a maximum length of 64. No spacing allowed.',
        }));
    }else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          name: '',
        }));
      }
    
      setFormData({
        ...formData,
        name: value,
      });
  }
  
  const handleUrlChange = (e) => {
    const value = e.target.value;
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  
    if (!urlPattern.test(value) && value !== '') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        url: 'Please enter a valid URL',
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        url: '',
      }));
    }
  
    setFormData({
      ...formData,
      url: value,
    });
  };
  
  const validateStep = (step) => {
    switch (step) {
      case 0:
        // Validate the Basic Info step
        return formData.name.trim() !== '' && isValidToolName(formData.name) && formData.description.trim() !== '' && isValidUrl(formData.url);
      case 1:
        // Validate the Parameters step (ensure at least one parameter is valid)
        return formData.params.length === 0 || formData.params.every(param =>
          param.paramName.trim() !== '' && param.paramType.trim() !== ''
        );
      case 2:
        // Validate the Security Settings step (optional validation logic)
        return true; // Adjust as needed
      default:
        return true;
    }
  };
  

  // Handle parameter changes
  const handleParamChange = (index, field, value) => {
    const updatedParams = [...formData.params];
    updatedParams[index] = {
      ...updatedParams[index],
      [field]: value
    };
    setFormData({
      ...formData,
      params: updatedParams
    });
  };

    const handleAddParameter = () => {
    setFormData((prevFormData) => ({
        ...prevFormData,
        params: [
        ...prevFormData.params,
        { paramName: '', paramType: 'string', required: false, description: '' } // Default new parameter structure
        ]
    }));
    };

    // Function to remove a parameter field
    const handleRemoveParameter = (index) => {
        setFormData((prevFormData) => ({
        ...prevFormData,
        params: prevFormData.params.filter((_, i) => i !== index)
    }));
    };

    const handleNext = () => {
        if (validateStep(currentStep)) {
          setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
        } else {
          toast({
            title: 'Validation Error',
            description: 'Please fill in all required fields before proceeding.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      };
      
  const handlePrev = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

  const handleAddTool = () => {
    setFormData({
      name: '',
      description: '',
      url: '',
      async: false,
      params: [],
      security: {
        headers: {},
        queryParams: {},
        bodyParams: {},
        tokenType: ''
      }
    });
    setEditingToolId(null);
    setShowForm(true);
  };

  const handleCancel = () => {
    setShowForm(false);
    resetForm();
  }

  // Handle editing a tool
  const handleEditTool = (tool) => {
    setFormData(tool);
    setEditingToolId(tool._id);
    setShowForm(true);
  };

  // Handle deleting a tool
  const handleDeleteTool = async (toolId) => {
    try {
      await axios.delete(`/api/tools/${toolId}`);
      setTools(tools.filter(tool => tool._id !== toolId));
      toast({
        title: 'Tool deleted',
        description: 'The tool has been deleted successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting tool:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete the tool.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handle saving the form
  const handleSave = async () => {
    try {
      if (editingToolId) {
        await axios.put(`/api/tools/${editingToolId}`, formData);
      } else {
        await axios.post('/api/tools', formData);
      }
      resetForm();
      setShowForm(false);
      await fetchTools();
      toast({
        title: 'Tool saved',
        description: 'The tool has been saved successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving tool:', error);
      toast({
        title: 'Error',
        description: 'Failed to save the tool.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
    <Helmet>
      <title>Setup Tools</title>
      <meta name="description" content="Setup Tools for Assistants" />
    </Helmet>
    <Flex height="100vh" justifyContent="space-between">
      {/* Left Navigation Column */}
      <Navigation />

      <Box p={8} flex="1">
          {!showForm ? (
            <>
              <Heading mb={6}>Tool List</Heading>
              <Button colorScheme="orange" onClick={handleAddTool} mb={4}>Add New Tool</Button>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Description</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {tools.map((tool) => (
                    <Tr key={tool._id}>
                      <Td>{tool.name}</Td>
                      <Td>{tool.description}</Td>
                      <Td>
                        <Button size="sm" colorScheme="blue" onClick={() => handleEditTool(tool)}>Edit</Button>
                        <Button size="sm" colorScheme="red" ml={2} onClick={() => handleDeleteTool(tool._id)}>Delete</Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </>
          ) : (
            <>
              <Heading mb={6}>{editingToolId ? 'Edit Tool' : 'Add New Tool'}</Heading>

              
      {/* Main Content Area */}
      <Box p={8} flex="1" >
        <Flex justifyContent={'space-between'}>
        <Heading mb={6}>Tool Setup</Heading>
      <Button onClick={handleCancel}>
        Cancel
        </Button>
        </Flex>
      
      <Stepper index={currentStep} mb={6}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus complete="✓" incomplete="⚪" active="➤" />
            </StepIndicator>
            <Box>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      
      <VStack spacing={4} align="stretch">
        {currentStep === 0 && (
          <>
            <FormControl isRequired>
              <FormLabel>Tool Name</FormLabel>
              <Input
                name="name"
                value={formData.name}
                onChange={handleToolNameChange}
                placeholder="Enter tool name"
              />
              {formErrors.name && <Text color="red.500">{formErrors.name}</Text>}
            </FormControl >
            <FormControl isRequired>
              <FormLabel>Description</FormLabel>
              <Textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Enter tool description"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>URL</FormLabel>
              <Input
                    name="url"
                    placeholder="Enter tool URL"
                    value={formData.url}
                    onChange={(e) => handleUrlChange(e)}
                    type="url"
                />
                {formErrors.url && <Text color="red.500">{formErrors.url}</Text>}
            </FormControl>
            <FormControl>
              <Checkbox
                name="async"
                isChecked={formData.async}
                onChange={(e) => setFormData({ ...formData, async: e.target.checked })}
              >
                Is this tool asynchronous?
              </Checkbox>
            </FormControl>
          </>
        )}

        {currentStep === 1 && (
          <>
            
                
            <Box>
      <Heading size="sm">Parameters</Heading>
      {formData.params.map((param, index) => (
        <Box key={index} mb={4} p={4} borderWidth="1px" borderRadius="md">
          <FormControl mb={2} isRequired> 
            <FormLabel>Parameter Name</FormLabel>
            <Input
              name="paramName"
              placeholder="Enter parameter name"
              value={param.paramName}
              onChange={(e) => handleParamChange(index, 'paramName', e.target.value)}
            />
          </FormControl>

          <FormControl mb={2}>
            <FormLabel>Type</FormLabel>
            <Select
              name="paramType"
              placeholder="Select type"
              value={param.paramType}
              onChange={(e) => handleParamChange(index, 'paramType', e.target.value)}
            >
              <option value="string">String</option>
              <option value="number">Number</option>
              <option value="boolean">Boolean</option>
              <option value="array">Array</option>
              <option value="object">Object</option>
            </Select>
          </FormControl>

          <FormControl mb={2}>
            <FormLabel>Required</FormLabel>
            <Checkbox
              name="required"
              isChecked={param.required}
              onChange={(e) => handleParamChange(index, 'required', e.target.checked)}
            >
              Required
            </Checkbox>
          </FormControl>

          <FormControl mb={2}>
            <FormLabel>Description</FormLabel>
            <Input
              name="description"
              placeholder="Enter description"
              value={param.description}
              onChange={(e) => handleParamChange(index, 'description', e.target.value)}
            />
          </FormControl>

          <Button colorScheme="red" size="sm" onClick={() => handleRemoveParameter(index)}>
            Remove
          </Button>
        </Box>
      ))}
      <Button colorScheme="teal" onClick={handleAddParameter}>Add Parameter</Button>
    </Box>
          </>
        )}

        {currentStep === 2 && (
          <>
            <Heading size="md" mb={4}>Security Settings</Heading>
            <FormControl>
              <FormLabel>Token Type</FormLabel>
              <Input
                name="tokenType"
                value={formData.security.tokenType}
                onChange={(e) => handleInputChange({ target: { name: 'security.tokenType', value: e.target.value } })}
                placeholder="e.g., Bearer"
              />
            </FormControl>
            {/* Additional inputs for headers, queryParams, bodyParams can be added here */}
          </>
        )}

        {currentStep === 3 && (
          <>
            <Heading size="md" mb={4}>Review</Heading>
            <pre>{JSON.stringify(formData, null, 2)}</pre>
          </>
        )}
      </VStack>

      <Flex justifyContent="space-between" mt={6}>
        <Button onClick={handlePrev} disabled={currentStep === 0}>Back</Button>
       
        {currentStep !== steps.length - 1 && (
        <Button onClick={handleNext} disabled={!validateStep(currentStep)}>
        Next
        </Button>
        )}

        {currentStep === steps.length - 1 && (
        <Button colorScheme="blue" onClick={handleSave}>
          Save Tool
        </Button>
        )}
      </Flex>
      </Box>
      </>
)}

      
    </Box>
    </Flex>
    </>
  );
};

export default ToolSetupPage;
