import { Box, List, ListItem,Checkbox } from '@chakra-ui/react';

const FileSelect = ({ formState, handleFileSelect, knowledgeBaseFiles }) => (
    <Box height="150px" width="200px" overflowY="auto"  p={2} borderColor="gray.200" borderRadius="md" >
        <List spacing={3}>
        {knowledgeBaseFiles.map((file) => (
            <ListItem key={file._id}>
            <Checkbox
                isChecked={formState.knowledgebases?.some((kb) => kb._id === file._id)}
                onChange={() => handleFileSelect(file._id)}
                style={{
                    whiteSpace: 'nowrap',    // Prevents wrapping
                    overflow: 'hidden',      // Hides overflow
                    textOverflow: 'ellipsis' // Adds ellipsis (...) for overflow text
                  }}
            >
                {file.fileName}
            </Checkbox>
            </ListItem>
        ))}
    </List>
    </Box>
);

export default FileSelect;