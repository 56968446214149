import React, { useEffect, useState } from 'react';
import {
  Box, Button, Input, Heading, VStack, Flex, 
  InputGroup, InputRightElement, FormControl, FormLabel, useToast
} from '@chakra-ui/react';
import Navigation from '../components/Navigation';
import axios from 'axios';
import useCheckAuth from '../hooks/useCheckAuth';
import {Helmet} from "react-helmet";

const ProfilePage = () => {
  useCheckAuth();
  
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    currentPassword: '',
    newPassword: '',
  });

  const [isEditing, setIsEditing] = useState(false); // Toggle between read-only and edit mode
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
  });

  const toast = useToast();

  // Fetch user data from backend on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('/api/profile');
        setFormState({
          ...formState,
          name: response.data.name,
          email: response.data.email,
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, []);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Toggle password visibility
  const handleTogglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  // Handle Save button click
  const handleSave = async () => {
    try {
      await axios.put('/api/profile', formState);
      toast({
        title: 'Profile updated.',
        description: 'Your profile information has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setIsEditing(false); // Switch back to read-only mode
    } catch (error) {
      console.error('Error updating profile:', error);
      toast({
        title: 'Error',
        description: 'There was an error updating your profile.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
    <Helmet>
      <title>Your Profile</title>
      <meta name="description" content="Your Profile and Details" />
    </Helmet>
    <Flex>
      <Navigation /> {/* Sidebar Navigation */}

      <Box p={8} flex="1">
        <Heading size="lg" mb={6}>Profile</Heading>

        {/* Profile Form */}
        <VStack spacing={6} align="stretch">
          {/* Name Field */}
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={formState.name}
              onChange={handleInputChange}
              bg={isEditing ? 'gray.900' : 'gray.100' }
              color={isEditing ? 'gray.100' : 'gray' }
              isReadOnly={!isEditing} // Read-only unless editing
            />
          </FormControl>

          {/* Email Field */}
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              value={formState.email}
              onChange={handleInputChange}
              bg={isEditing ? 'gray.900' : 'gray.100' }
              color={isEditing ? 'gray.100' : 'gray' }
              isReadOnly={!isEditing} // Read-only unless editing
            />
          </FormControl>

          {/* Current Password Field */}
          <FormControl>
            <FormLabel>Current Password</FormLabel>
            <InputGroup size="md">
              <Input
                type={showPassword.currentPassword ? 'text' : 'password'}
                name="currentPassword"
                value={formState.currentPassword}
                onChange={handleInputChange}
                bg={isEditing ? 'gray.900' : 'gray.100' }
                color={isEditing ? 'gray.100' : 'gray' }
                isReadOnly={!isEditing} // Read-only unless editing
              />
              {isEditing && ( // Show toggle button only in edit mode
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => handleTogglePasswordVisibility('currentPassword')}
                  >
                    {showPassword.currentPassword ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>

          {/* New Password Field */}
          <FormControl>
            <FormLabel>New Password</FormLabel>
            <InputGroup size="md">
              <Input
                type={showPassword.newPassword ? 'text' : 'password'}
                name="newPassword"
                value={formState.newPassword}
                onChange={handleInputChange}
                bg={isEditing ? 'gray.900' : 'gray.100' }
                color={isEditing ? 'gray.100' : 'gray' }
                isReadOnly={!isEditing} // Read-only unless editing
              />
              {isEditing && ( // Show toggle button only in edit mode
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => handleTogglePasswordVisibility('newPassword')}
                  >
                    {showPassword.newPassword ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>

          {/* Action Buttons */}
          <Flex justifyContent="space-between">
            <Button
              onClick={() => setIsEditing(!isEditing)}
              colorScheme={isEditing ? 'gray' : 'blue'}
            >
              {isEditing ? 'Cancel' : 'Edit'}
            </Button>
            {isEditing && (
              <Button colorScheme="teal" onClick={handleSave}>
                Save Changes
              </Button>
            )}
          </Flex>
        </VStack>
      </Box>
    </Flex>
    </>
  );
};

export default ProfilePage;
