import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Button, Input, Heading, VStack, List, ListItem, Text, Flex, Table, Thead, Tbody, Tr, Th, Td,
  useToast
} from '@chakra-ui/react';
import axios from 'axios';
import Navigation from '../components/Navigation';
import {Helmet} from "react-helmet";

const KnowledgeBasePage = () => {
  const [file, setFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef(null);
  const toast = useToast();

  // Fetch uploaded files on page load
  useEffect(() => {
    const fetchUploadedFiles = async () => {
      try {
        const response = await axios.get('/api/knowledgebase/');  // Fetch previously uploaded files from the server
        setUploadedFiles(response.data);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    fetchUploadedFiles();
  }, []);

  // Handle file input change
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Upload file to the server
  const handleUpload = async () => {
    if (!file) {
      toast({
        title: 'File Upload',
        description: 'Please select a file to upload.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post('/api/knowledgebase/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setUploadedFiles((prev) => [...prev, response.data]);  // Add the new uploaded file to the list
      setFile(null);  // Reset file input
      toast({
        title: 'File uploaded successfully!',
        description: 'Your file have been uploaded successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      fileInputRef.current.value = '';  
    } catch (error) {
      console.error('Error uploading file:', error);
      toast({
        title: 'File uploaded failed!',
        description: 'File failed to upload',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle removing a file
  const handleRemoveFile = async (fileId) => {
    try {
      await axios.delete(`/api/knowledgebase/${fileId}`);  // Send a DELETE request to remove the file

      setUploadedFiles((prev) => prev.filter((file) => file._id !== fileId));  // Update the list of uploaded files
      toast({
        title: 'File removed successfully!',
        description: 'Your file have been removed successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error removing file:', error);
      toast({
        title: 'Failed to remove file',
        description: 'Your file removal has failed.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
    <Helmet>
      <title>Upload your knowledgebase for Assistant</title>
      <meta name="description" content="upload your knowledgebase for assistant" />
    </Helmet>
    <Flex>
      {/* Navigation Sidebar */}
      <Navigation />

      {/* Main Content */}
      <Box p={8} flex="1">
        <Heading size="lg" mb={6}>Knowledge Base</Heading>

        <VStack spacing={4} align="flex-start">
          {/* File Upload Section */}
          <Flex alignItems="left" justifyContent="left"
            direction={{
                base: "column",
                md: "row",
                lg: "row"
            }}>
            <Input
              pt={1}
              type="file"
              onChange={handleFileChange}
              ref={fileInputRef}
              width="auto"  // Adjust width of file input field
            />
            <Button
              onClick={handleUpload}
              isLoading={loading}
              ml={4}
              mt={{
                base: 4,
                md: 0,
                lg: 0
              }}
            >
              Upload File
            </Button>
          </Flex>
          {/* Uploaded Files Table */}
          <Heading size="md" mt={8}>Uploaded Files</Heading>

          <Table variant="simple" size="md" mt={4}>
            <Thead>
              <Tr>
                <Th>File Name</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {uploadedFiles.length > 0 ? (
                uploadedFiles.map((file) => (
                  <Tr key={file._id}>
                    <Td>{file.fileName}</Td>
                    <Td>
                      <Button
                        colorScheme="red"
                        size="sm"
                        onClick={() => handleRemoveFile(file._id)}
                      >
                        Remove
                      </Button>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={2}><Text>No files uploaded yet.</Text></Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </VStack>
      </Box>
    </Flex>
    </>
  );
};

export default KnowledgeBasePage; 
