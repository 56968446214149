import React, { useEffect, useState } from 'react';
import {
  Box, Button, Input, Heading, VStack, List, ListItem, Text, Flex, Select, useToast, Grid, GridItem
} from '@chakra-ui/react';
import axios from 'axios';
import Navigation from '../components/Navigation';
import useCheckAuth from '../hooks/useCheckAuth';
import {Helmet} from "react-helmet";

const PhoneNumberPage = () => {
  useCheckAuth();

  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [assistants, setAssistants] = useState([]);
  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const phoneResponse = await axios.get('/api/phone-numbers');
        setPhoneNumbers(phoneResponse.data);

        const assistantsResponse = await axios.get('/api/assistants');
        setAssistants(assistantsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleFetchPhoneNumbers = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post('/api/phone-numbers');
      setPhoneNumbers(response.data);

      const assistantsResponse = await axios.get('/api/assistants');
      setAssistants(assistantsResponse.data);
    } catch (error) {
      console.error('Error fetching phone numbers:', error);
      setError('Failed to fetch phone numbers. Please check your API credentials.');
    } finally {
      setLoading(false);
    }
  };

  const handleTagAssistant = async (phoneNumberId, assistantId, direction) => {
    try {
      const response = await axios.post('/api/phone-numbers/tag', {
        phoneNumberId,
        assistantId,
        direction,
      });

      setPhoneNumbers((prevPhoneNumbers) =>
        prevPhoneNumbers.map((phoneNumber) =>
          phoneNumber.sid === phoneNumberId ? response.data : phoneNumber
        )
      );

      toast({
        title: 'Changes Saved',
        description: 'Your settings have been saved successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error tagging assistant:', error);
    }
  };

  return (
    <>
    <Helmet>
      <title>Retrieve Your Phone Number</title>
      <meta name="description" content="Retrieve Your Phone Number From Twilio" />
    </Helmet>
    <Flex height="100vh" justifyContent="space-between">
      {/* Left Navigation Column */}
      <Navigation />

      {/* Main Content Area */}
      <Box p={8} flex="1" >
        <Heading size="lg" mb={6}>Phone Numbers</Heading>

        <Button onClick={handleFetchPhoneNumbers} mb={6}>
          {loading ? 'Fetching...' : 'Fetch Phone Numbers'}
        </Button>

        {error && <Text color="red.500" mb={4}>{error}</Text>}

        {/* Grid for Phone Numbers */}
        <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
          {phoneNumbers.length === 0 && !loading ? (
            <Text>No phone numbers found. Please fetch using the button above.</Text>
          ) : (
            phoneNumbers.map((number) => (
              <GridItem key={number.sid} bg="gray.100" p={4} borderRadius="md" borderWidth={1} borderColor="gray.900">
                <VStack align="stretch" spacing={4} >
                  <Box>
                    <Text fontWeight="bold">{number.phoneNumber}</Text>
                    <Text fontSize="sm" color="gray.600">
                      {number.friendlyName}
                    </Text>
                  </Box>

                  <Box >
                    <Text fontWeight="bold">Inbound Assistant</Text>
                    <Select bg="gray.900" color="gray.100"
                      placeholder="Select Inbound Assistant"
                      onChange={(e) =>
                        handleTagAssistant(number.sid, e.target.value, 'inbound')
                      }
                      value={number.inboundAssistant || ''}
                    >
                      {assistants.map((assistant) => (
                        <option key={assistant._id} value={assistant._id}>
                          {assistant.name}
                        </option>
                      ))}
                    </Select>

                    <Text fontWeight="bold" mt={4}>Outbound Assistant</Text>
                    <Select bg="gray.900" color="gray.100"
                      placeholder="Select Outbound Assistant"
                      onChange={(e) =>
                        handleTagAssistant(number.sid, e.target.value, 'outbound')
                      }
                      value={number.outboundAssistant || ''}
                    >
                      {assistants.map((assistant) => (
                        <option key={assistant._id} value={assistant._id}>
                          {assistant.name}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </VStack>
              </GridItem>
            ))
          )}
        </Grid>
      </Box>
    </Flex>
    </>
  );
};

export default PhoneNumberPage;
