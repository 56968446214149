import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const useCheckAuth = (isPublicPage = false) => {
  const navigate = useNavigate();
  const location = useLocation(); // To determine the current page

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await axios.get('/api/check-session'); // Check if the user is logged in
        const isAuthenticated = response.data.isAuthenticated;

        if (isAuthenticated && isPublicPage) {
          // If authenticated and on a public page, redirect to the dashboard
          navigate('/dashboard', { replace: true });
        } else if (!isAuthenticated && !isPublicPage) {
          // If not authenticated and on a protected page, redirect to login
          navigate('/login', { replace: true });
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        // If an error occurs, redirect to login if on a protected page
        if (!isPublicPage) {
          navigate('/login', { replace: true });
        }
      }
    };

    checkSession();
  }, [navigate, isPublicPage, location.pathname]);
};

export default useCheckAuth;
