import React, { useEffect, useState } from 'react';
import { 
  Box, Button, Heading, Table, Thead, Tbody, Tr, Th, Td, 
  Flex, Input, FormControl, FormLabel, Text, Menu, 
  MenuButton, MenuList, MenuItemOption, MenuOptionGroup, HStack, Stack, 
  useToast, IconButton, Spinner, Tooltip
} from '@chakra-ui/react';
import { MdSettings } from 'react-icons/md';
import { ExternalLinkIcon, PhoneIcon, ArrowBackIcon, ArrowForwardIcon, DownloadIcon } from '@chakra-ui/icons';
import Navigation from '../components/Navigation';
import axios from 'axios';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver'; // For triggering download

const BillingPage = () => {
//   const [billings, setBillings] = useState([]);
    const [filteredBillings, setFilteredBillings] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [fromNumber, setFromNumber] = useState('');
    const [toNumber, setToNumber] = useState('');
    const [callSid, setCallSid] = useState('');
    const [visibleColumns, setVisibleColumns] = useState([
    'date', 'from', 'to', 'duration', 'chargedDuration', 'totalCost', 'recordingUrl', 'direction' // Call ID hidden by default
    ]);
    const toast = useToast();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);


    const exportToExcel = async () => {
        try {
            const data = await fetchAllBillings(); // Fetch billing data

            if (data.length === 0) {
                console.warn('No data to export');
                return;
            }

            // Extract unique headers from the data (collect all possible keys)
            const headers = Array.from(
                new Set(data.flatMap((record) => Object.keys(record)))
            );

            // Create a new workbook and add a worksheet
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Billing Records');

            // Add headers as the first row
            worksheet.addRow(headers);

            // Add each record as a new row, ensuring consistent column order
            data.forEach((record) => {
                const rowData = headers.map((header) => record[header] || ''); // Fill missing values with ''
                worksheet.addRow(rowData);
            });

            // Generate the Excel file as a Blob
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
            });

            // Trigger the download using file-saver
            saveAs(blob, 'billing_records.xlsx');
            console.log('Excel file exported successfully');
        } catch (error) {
            console.error('Error exporting Excel file:', error);
        }
    };

    const formatDateTime = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

  const fetchAllBillings = async (limit = 100, maxPages = 50) => {
    let allBillings = [];
    let page = 1;
    let hasMoreData = true;
  
    while (hasMoreData && page <= maxPages) {
      try {
        const response = await axios.get(`/api/billings?page=${page}&limit=${limit}`);
        const { billings } = response.data;
  
        if (billings.length > 0) {
          allBillings = [...allBillings, ...billings]; // Merge data
          page += 1; // Move to the next page
        } else {
          hasMoreData = false; // Stop if no more data
        }
      } catch (error) {
        console.error('Error fetching billings:', error);
        break; // Stop on error
      }
    }
  
    return allBillings;
  };

  const fetchBillings = async (page = 1, filters = {}) => {
    setLoading(true);
    try {
        const params = new URLSearchParams({
            page: page,
            limit: String(10),
            ...filters, // Spread the filter parameters
          });
      
        const response = await axios.get(`/api/billings?${params.toString()}`);
        const { billings, totalPages } = response.data;
        // setBillings(billings);
        setFilteredBillings(billings);
        setTotalPages(totalPages);
        setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching billings:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBillings();
  }, []);

  const handlePageChange = (page) => {
    fetchBillings(page);
  };

  const applyFilters = () => {
    
    const filters = {};

    if (startDate && endDate) {
        filters.startDate = startDate;
        filters.endData = endDate;
    }

    if (fromNumber) {
        filters.from = fromNumber;
    }

    if (toNumber) {
        filters.to = toNumber;
    }

    if (callSid) {
        filters.callSid = callSid;
    }
    fetchBillings(1, filters);
  };

  const resetFilters = () => {
    setStartDate('');
    setEndDate('');
    setFromNumber('');
    setToNumber('');
    setCallSid('');
    fetchBillings(1);
    // setFilteredBillings(billings);
  };

  const formatCurrency = (amount) => `SGD ${amount.toFixed(2)}`;


  const toggleColumn = (column) => {
    setVisibleColumns((prevColumns) =>
      prevColumns.includes(column)
        ? prevColumns.filter((col) => col !== column)
        : [...prevColumns, column]
    );
  };

    const handleDownload = (callLog, callSid) => {
        const blob = new Blob([callLog], { type: 'text/plain' }); // Create a blob from the call log
        const url = URL.createObjectURL(blob); // Generate a URL for the blob
        const link = document.createElement('a'); // Create a temporary link element
        link.href = url;
        link.download = `${callSid}-call-log.txt`; // Set the file name dynamically
        document.body.appendChild(link); // Append the link to the DOM
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up the DOM
        URL.revokeObjectURL(url); // Revoke the blob URL to free up memory
    };

  return (
    <Flex height="100vh" justifyContent="space-between">
      <Navigation />

      <Box p={8} flex="1" overflowX="auto">
        <Flex justifyContent="space-between" alignItems="center" mb={6}>
            <Heading size="lg">Billing Records</Heading>
            
          <Menu closeOnSelect={false}>
            <MenuButton as={IconButton} icon={<MdSettings />} aria-label="Settings" />
            <MenuList minWidth="240px">
              <MenuOptionGroup title="Columns" type="checkbox" defaultValue={visibleColumns}>
                <MenuItemOption value="date" onClick={() => toggleColumn('date')}>Date</MenuItemOption>
                <MenuItemOption value="callSid" onClick={() => toggleColumn('callSid')}>Call ID</MenuItemOption> {/* Hidden by default */}
                <MenuItemOption value="from" onClick={() => toggleColumn('from')}>From</MenuItemOption>
                <MenuItemOption value="to" onClick={() => toggleColumn('to')}>To</MenuItemOption>
                <MenuItemOption value="duration" onClick={() => toggleColumn('duration')}>Duration (mins)</MenuItemOption>
                <MenuItemOption value="chargedDuration" onClick={() => toggleColumn('chargedDuration')}>Charged Duration (mins)</MenuItemOption>
                <MenuItemOption value="rate" onClick={() => toggleColumn('rate')}>Rate (SGD/min)</MenuItemOption>
                <MenuItemOption value="totalCost" onClick={() => toggleColumn('totalCost')}>Total Cost (SGD)</MenuItemOption>
                <MenuItemOption value="recordingUrl" onClick={() => toggleColumn('recordingUrl')}>Recording</MenuItemOption>
                <MenuItemOption value="direction" onClick={() => toggleColumn('direction')}>Direction</MenuItemOption>
                <MenuItemOption value="callLog" onClick={() => toggleColumn('callLog')}>Log</MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Flex>

        <Flex align="center" gap={3} mb={4} direction={{
              base: 'column',  // 1 column on small screens (mobile)
              md: 'column',    // 2 columns on medium screens (tablets)
              lg: 'row' }} >
          <FormControl>
          <Flex align="center" gap={2}> 
            <FormLabel width="100px">Call SID</FormLabel>
            <Input 
              type="text" 
              placeholder="Enter Call SID" 
              value={callSid} 
              width={300}
              onChange={(e) => setCallSid(e.target.value)} 
            />
            </Flex>
          </FormControl>
          <FormControl>
          <Flex align="center" gap={2}> 
            <FormLabel width="100px">Start Date</FormLabel>
            <Input 
              type="date" 
              value={startDate} 
              width={300}
              onChange={(e) => setStartDate(e.target.value)} 
            />
            </Flex>
          </FormControl>
          <FormControl>
          <Flex align="center" gap={2}> 
            <FormLabel width="100px">End Date</FormLabel>
            <Input 
              type="date" 
              value={endDate} 
              width={300}
              onChange={(e) => setEndDate(e.target.value)} 
            />
            </Flex>
          </FormControl>
        </Flex>
        <Flex gap={3} mb={4} direction={{
              base: 'column',  // 1 column on small screens (mobile)
              md: 'column',    // 2 columns on medium screens (tablets)
              lg: 'row' }}>
            <FormControl>
            <HStack  gap={2}> 
            <FormLabel width="100px">Call From</FormLabel>
            <Input 
              placeholder="From"
              name="from"
              value={fromNumber}
              width={300}
              onChange={(e) => setFromNumber(e.target.value)} 
            />
            </HStack>
            </FormControl>
            <FormControl>
                <HStack gap={2}>
                    <FormLabel width="100px">Call To</FormLabel>
                    <Input 
                    placeholder="To"
                    name="to"
                    value={toNumber}
                    width={300}
                    onChange={(e) => setToNumber(e.target.value)} 
                    />
                </HStack>
            </FormControl>
        </Flex>

        <Flex gap={4} mb={4}>
          <Button colorScheme="teal" onClick={applyFilters}>
            Apply Filters
          </Button>
          <Button variant="outline" onClick={resetFilters}>
            Reset Filters
          </Button>
        </Flex>
        {loading ? (
          <Spinner size="lg" />
        ) : (
        <>
        <Flex justifyContent="flex-end" mb={4} gap={2}>
            <Button colorScheme="blue" onClick={() => exportToExcel()}>
                Export to Excel
            </Button>
        </Flex>
        <Table variant="simple" size="lg">
          <Thead position="sticky" top={0} bg="gray.100" zIndex={1}>
            <Tr>
              {visibleColumns.includes('date') && <Th>Date</Th>}
              {visibleColumns.includes('callSid') && <Th>Call ID</Th>}
              {visibleColumns.includes('from') && <Th>From</Th>}
              {visibleColumns.includes('to') && <Th>To</Th>}
              {visibleColumns.includes('duration') && <Th>Duration (mins)</Th>}
              {visibleColumns.includes('chargedDuration') && <Th>Charged Duration (mins)</Th>}
              {visibleColumns.includes('rate') && <Th>Rate (SGD/min)</Th>}
              {visibleColumns.includes('totalCost') && <Th>Total Cost (SGD)</Th>}
              {visibleColumns.includes('recordingUrl') && <Th>Recording</Th>}
              {visibleColumns.includes('direction') && <Th>Direction</Th>}
              {visibleColumns.includes('callLog') && <Th>Log</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {filteredBillings.map((billing) => (
              <Tr key={billing.callSid}>
                {visibleColumns.includes('date') && <Td>{formatDateTime(billing.updatedAt)}</Td>}
                {visibleColumns.includes('callSid') && <Td>{billing.callSid}</Td>}
                {visibleColumns.includes('from') && <Td>{billing.from}</Td>}
                {visibleColumns.includes('to') && <Td>{billing.to}</Td>}
                {visibleColumns.includes('duration') && <Td>{(billing.duration / 1000 / 60).toFixed(2)} mins</Td>}
                {visibleColumns.includes('chargedDuration') && <Td>{(billing.chargedDuration / 60).toFixed(2)} mins</Td>}
                {visibleColumns.includes('rate') && <Td>{formatCurrency(billing.rate)}</Td>}
                {visibleColumns.includes('totalCost') && <Td>{formatCurrency(billing.totalCost)}</Td>}
                {visibleColumns.includes('recordingUrl') && <Td>{billing.recordingUrl ?(<Button
                    as="a" href={billing.recordingUrl} target="_blank" rel="noopener noreferrer" colorScheme="blue" leftIcon={<ExternalLinkIcon />}>
                    Listen
                </Button>):"No Recording"}</Td>}
                {visibleColumns.includes('direction') && <Td>
                    <Tooltip label={billing.direction === 'outbound' ? 'Outbound Call' : 'Inbound Call'} aria-label="Call direction tooltip">
                    <Stack direction="row" align="center" spacing={1}>
                    {billing.direction === 'outbound' ? (
                        <>
                        <PhoneIcon color="red.500" />
                        <ArrowForwardIcon color="red.500" />
                        </>
                    ) : (
                        <>
                        <PhoneIcon color="green.500" />
                        <ArrowBackIcon color="green.500" />
                        </>
                    )}
                    </Stack>
                    </Tooltip>
                </Td>}
                {visibleColumns.includes('callLog') && <Td>{billing.callLog ?
                    (<Tooltip label="Download Call Log" aria-label="Download tooltip">
                    <IconButton
                        icon={<DownloadIcon />}
                        colorScheme="teal"
                        variant="ghost"
                        aria-label="Download Call Log"
                        onClick={() => handleDownload(billing.callLog, billing.callSid)}
                    />
                    </Tooltip>):"NA"}
                </Td>}
              </Tr>
            ))}
          </Tbody>
        </Table>
        </>
        )}

        {/* Pagination Controls */}
        <Flex justifyContent="space-between" mt={4}>
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            isDisabled={currentPage === 1}
          >
            Previous
          </Button>
          <Text>Page {currentPage} of {totalPages}</Text>
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            isDisabled={currentPage === totalPages}
          >
            Next
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default BillingPage;
