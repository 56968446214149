// src/theme.js
import { extendTheme } from '@chakra-ui/react';

// Customize the Chakra theme
const customTheme = extendTheme({
    colors: {
      brand: {
        50: '#ffefde',   // Lightest shade (for backgrounds, highlights, etc.)
      100: '#fdd4b4',
      200: '#f6ba88',
      300: '#f19e5b',
      400: '#ec822d',
      500: '#d26913',  // Primary shade (for buttons, links, etc.)
      600: '#a4510d',
      700: '#763908',
      800: '#482200',  // Darker shades (for hover states or text)
      900: '#1d0a00',  //
      },
    },

    components: {
        Button: {
          // Default styles for the Button component
          defaultProps: {
            colorScheme: 'orange',  // Set the default color scheme for all buttons
          },
        },
      }
  });


export default customTheme;
