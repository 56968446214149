import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { Spinner, Center } from '@chakra-ui/react';  // Optional for loading spinner

// ProtectedRoute component
const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);  // null initially (unknown status)
  const [loading, setLoading] = useState(true);  // Track loading state

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        // Make API call to check if user is authenticated
        const response = await axios.get('/api/check-session');
        setIsAuthenticated(response.data.isAuthenticated);
      } catch (error) {
        console.error('Error checking session:', error);
        setIsAuthenticated(false);  // Assume not authenticated if there's an error
      } finally {
        setLoading(false);  // Stop loading once we have the response
      }
    };

    checkAuthentication();  // Check authentication when component mounts
  }, []);

  // Show a loading spinner while waiting for the authentication check
  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  // If not authenticated, redirect to login
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // If authenticated, allow access to the protected route
  return children;
};

export default ProtectedRoute;
