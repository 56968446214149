import React, { useEffect, useState } from 'react';
import { Box, Flex, VStack, Heading, Text, useToast } from '@chakra-ui/react';
import { MdDashboard, MdSettings } from 'react-icons/md';
import axios from 'axios';
import Navigation from '../components/Navigation';
import RightPanel from '../components/RightPanel';
import {Helmet} from "react-helmet";

const AssistantPage = () => {
  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [knowledgeBaseFiles, setKnowledgeBaseFiles] = useState([]);
  const [tools, setTools] = useState([]);
  const toast = useToast(); // Use toast to show notifications

  const fetchAssistants = async () => {
    try {
      const response = await axios.get('/api/assistants');
      setAssistants(response.data);
      return response.data?.length>0?response.data[0]:null;
    } catch (error) {
      console.error('Error fetching assistants:', error);
      toast({
        title: 'Error fetching assistants',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchKnowledgeBaseFiles = async () => {
    try {
      const response = await axios.get(`/api/knowledgebase`);
      setKnowledgeBaseFiles(response.data);
    } catch (error) {
      console.error('Error fetching knowledge base files:', error);
      toast({
        title: 'Error fetching knowledge base files',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchTools = async () => {
    try {
      const response = await axios.get(`/api/tools`);
      setTools(response.data);
    } catch (error) {
      console.error('Error fetching tools:', error);
      toast({
        title: 'Error fetching tools',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const preload = async () => {
        fetchKnowledgeBaseFiles();
        fetchTools();
        const firstAssistant = await fetchAssistants();
        setSelectedAssistant(firstAssistant); // Select the first assistant by default
    }

    preload();
  }, []);

  const handleSave = async (assistant) => {
    try {
        const response = await axios.put(`/api/assistants/${assistant._id}`, assistant);
        const success = response.data?.success;
        
        if(success === true){
            console.log('after save',response.data?.data);
            setSelectedAssistant(response.data?.data);
        }

        fetchAssistants();

        toast({
            title: 'Assistant saved successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    } catch (error) {
      console.error('Error saving assistant:', error);
      toast({
        title: 'Error saving assistant',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (assistantToDelete) => {
    try {
      // If there's no ID, remove from state without backend call (unsaved assistant)
      if (!assistantToDelete?.newAssistant) {
        await axios.delete(`/api/assistants/${assistantToDelete._id}`);
      }
      setAssistants((prevAssistants) => prevAssistants.filter((assistant) => assistant._id !== assistantToDelete._id));
      setSelectedAssistant(assistants.length>0?assistants[0]:null);
      toast({
        title: 'Assistant deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting assistant:', error);
      toast({
        title: 'Error deleting assistant',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNewAssistant = () => {
    const defaultName = `Assistant #${assistants.length + 1}`;
    const newAssistant = {
      _id: defaultName,  // New assistant has no ID
      name: defaultName,
      systemPrompt: '',
      temperature: 0.7,
      maxTokens: 250,
      idleMessage: 'Are you there?',
      transcriptionModelId: 'nova-2',
      callTimeout: 20,
      twilioRecording: false,
      newAssistant:true,
      model: 'complete:gpt-4o-mini-2024-07-18',
      tools:[]
    };

    setAssistants((prevAssistants) => [...prevAssistants, newAssistant]);
    setSelectedAssistant(newAssistant);
  };

  return (
    <>
    <Helmet>
      <title>Assistants</title>
      <meta name="description" content="Create your assistant for voice bot" />
    </Helmet>
    <Flex height="100vh" justifyContent="space-between">
      {/* Left Navigation Column */}
      <Navigation />

      {/* Center Column - List of Assistants */}
      <Box minW={200} bg="white" p={4} overflowY="scroll">
        <Heading size="md" mb={4}>Assistants</Heading>
        <VStack spacing={4} align="stretch">
          {assistants.map((assistant) => (
            <Box
              key={assistant._id || assistant.name}
              p={4}
              borderWidth="1px"
              borderRadius="md"
              bg={selectedAssistant?._id === assistant._id ? 'brand.500' : 'white'}
              color={selectedAssistant?._id === assistant._id ? 'white' : 'black'}
              cursor="pointer"
              onClick={() => setSelectedAssistant(assistant)}
            >
              <Text fontWeight="semibold" fontSize="lg">
                {assistant.name || "New Assistant"}
              </Text>
              <Text fontSize="sm">Click to edit</Text>
            </Box>
          ))}
        </VStack>
      </Box>

      {/* Right Column - Assistant Details */}
      <RightPanel
        onNewAssistant={handleNewAssistant}
        selectedAssistant={selectedAssistant}
        onSave={handleSave}
        onDelete={handleDelete}  // Pass the delete handler to RightPanel
        knowledgeBaseFiles={knowledgeBaseFiles}
        tools={tools}
      />
    </Flex>
    </>
  );
};

export default AssistantPage;
