import { Button, Heading, Flex } from '@chakra-ui/react';

const RightPanelHeader = ({ selectedAssistant, handleOpen, handleSave, onNewAssistant }) => (
    <Flex justifyContent="space-between" alignItems="center" mb={4} direction={{
        base: "column",
        md: "row",
        lg: "row"
      }}>
        {selectedAssistant && (
        <Heading size="md">Assistant - Configuration</Heading>
        )}
        <Flex direction={{
          base: "column",
          md: "column",
          lg: "row"
        }} gap={{
          base: 1,
          md: 1,
          lg: 0
        }}>
        {selectedAssistant && (
          <Button 
          colorScheme="yellow" 
          mr={4} 
          onClick={handleOpen}
          disabled={!!selectedAssistant.newAssistant}
          >
            Call Me
          </Button>
            )}
            {/* {selectedAssistant && (
          <Button colorScheme="cyan" mr={4} onClick={connectAssistant}>
            Talk to Me
          </Button>
            )} */}
          <Button colorScheme="teal" mr={4} onClick={onNewAssistant}>
            New Assistant
          </Button>
          {selectedAssistant && (
          <Button onClick={handleSave}>
            Save Changes
          </Button>
          )}
        </Flex>
      </Flex>
);

export default RightPanelHeader;