import { Checkbox, List, ListItem, Box } from '@chakra-ui/react';

const ToolSelect = ({ formState, handleToolSelect, tools }) => (
    <Box height="150px" width="200px" overflowY="auto"  p={2} borderColor="gray.200" borderRadius="md" >
    <List spacing={3}>
    {tools.map((t) => (
        <ListItem key={t._id}>
        <Checkbox
            // Check if the file ID is included in the assistant's tools
            isChecked={formState.tools?.some((tool) => {
                // console.log(tool._id, 'vs', t._id);
                return tool._id === t._id;
            })}
            onChange={() => handleToolSelect(t._id)}
            style={{
                whiteSpace: 'nowrap',    // Prevents wrapping
                overflow: 'hidden',      // Hides overflow
                textOverflow: 'ellipsis' // Adds ellipsis (...) for overflow text
              }}
        >
            {t.name}
        </Checkbox>
        </ListItem>
    ))}
    </List>
    </Box>
);

export default ToolSelect;