import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register required components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const MonthlySpendChart = ({ monthlySpendData }) => {
  // Prepare data and labels for the chart
  const labels = monthlySpendData.map((data) => data.month);
  const data = {
    labels,
    datasets: [
      {
        label: 'Total Spend (SGD)',
        data: monthlySpendData.map((data) => data.totalSpend),
        backgroundColor: '#fff',
        borderColor: 'brand.300',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        color: '#fff',
        labels: {
            color: '#fff', // Text color from Chakra theme
            font: {
              size: 14,
            },
        },
      },
      title: {
        display: true,
        text: 'Monthly Spend for the Year',
        color: '#fff'
      },
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          color: '#fff'
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Total Spend (SGD)',
          color: '#fff'
        },
        ticks: {
            color: '#fff'
        }
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default MonthlySpendChart;
