import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import { Box, Button, Input, VStack, FormControl, FormLabel, FormErrorMessage, 
    Heading, Text, Link, Alert, AlertIcon } from '@chakra-ui/react';
import useCheckAuth from '../hooks/useCheckAuth';
import {Helmet} from "react-helmet";

// Validation schema using Yup
const schema = yup.object().shape({
  email: yup.string().email('Invalid email format').required('Email is required'),
  password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

const LoginPage = () => {
  const navigate = useNavigate();
  useCheckAuth(true);

  const [loginError, setLoginError] = useState(''); // State for login error message

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await axios.post('/api/login', data);
      
      if (response.data.error) {
        setLoginError(response.data.error); // Display server-side error message
      } else {
        navigate('/dashboard'); // Redirect to dashboard on success
      }
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            setLoginError(error.response.data.error); // Display specific error from backend
        } else {
            setLoginError('An unexpected error occurred. Please try again.'); // Generic fallback
        }
    }
  };

  return (
    <>
    <Helmet>
      <title>Login Page</title>
      <meta name="description" content="Login Page" />
    </Helmet>
    <Box maxW="md" mx="auto" mt={8} p={6} borderWidth={1} borderRadius="lg">
      <Heading mb={6} textAlign="center">Login</Heading>

      {/* Display login error message */}
      {loginError && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {loginError}
        </Alert>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          {/* Email Field */}
          <FormControl isInvalid={errors.email}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              placeholder="Enter your email"
              {...register('email')}
            />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>

          {/* Password Field */}
          <FormControl isInvalid={errors.password}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder="Enter your password"
              {...register('password')}
            />
            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
          </FormControl>

          {/* Login Button */}
          <Button type="submit" colorScheme="orange" width="full">
            Login
          </Button>

          {/* Links for Register and Forgot Password */}
          {/* <Text>
            Don't have an account?{' '}
            <Link as={RouterLink} to="/register" color="brand.500">
              Register
            </Link>
          </Text> */}
          <Text>
            <Link as={RouterLink} to="/forgot-password" color="brand.500">
              Reset your password
            </Link>
          </Text>
        </VStack>
      </form>
    </Box>
    </>
  );
};

export default LoginPage;
