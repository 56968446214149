import React, { useEffect, useState } from 'react';
import { 
  Box, Flex, Heading, Stat, StatLabel, StatNumber, StatHelpText, StatArrow, 
  Grid, useToast, Spinner, IconButton, GridItem
} from '@chakra-ui/react';
import { MdRefresh } from 'react-icons/md';
import axios from 'axios';
import Navigation from '../components/Navigation';
import MonthlySpendChart from '../components/MonthlySpendChart';
import CallDirectionChart from '../components/CallDirectionChart';

const DashboardPage = () => {
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    totalCalls: 0,
    totalMinutes: 0,
    totalSpend: 0,
    avgCostPerCall: 0,
    trend: 'neutral', // Can be 'increase', 'decrease', or 'neutral'
    monthlySpend: [],
    directionData: {
        inbound: 0, outbound: 0
    }
  });

  const toast = useToast();

  

  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/stats');
      setDashboardData(response.data);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch dashboard data.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <Flex height="100vh" justifyContent="space-between">
      <Navigation />

      <Box p={8} flex="1">
        <Flex justifyContent="space-between" alignItems="center" mb={6}>
          <Heading size="lg">Dashboard</Heading>
          <IconButton
            icon={<MdRefresh />}
            aria-label="Refresh Dashboard"
            onClick={fetchDashboardData}
            isLoading={loading}
          />
        </Flex>

        {loading ? (
          <Flex justifyContent="center" alignItems="center" height="100%">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }}
          color="#fff" 
          gap={6}>
            {/* Total Calls */}
            <GridItem>
                <Stat bg="gray.800" p={4} borderRadius="md">
                <StatLabel>Total Calls</StatLabel>
                <StatNumber>{dashboardData.totalCalls}</StatNumber>
                <StatHelpText>All time total calls</StatHelpText>
                </Stat>
            </GridItem>
            {/* Total Minutes */}
            <GridItem>
                <Stat bg="gray.800" p={4} borderRadius="md">
                <StatLabel>Total Minutes</StatLabel>
                <StatNumber>{dashboardData.totalMinutes.toFixed(2)} mins</StatNumber>
                <StatHelpText>All time total call duration</StatHelpText>
                </Stat>
            </GridItem>

            {/* Total Spend */}
            <GridItem>
                <Stat bg="gray.800"  p={4} borderRadius="md">
                <StatLabel>Total Spend</StatLabel>
                <StatNumber>SGD {dashboardData.totalSpend.toFixed(2)}</StatNumber>
                <StatHelpText>Total cost of all calls</StatHelpText>
                </Stat>
            </GridItem>

            {/* Average Cost Per Call */}
            <GridItem>
                <Stat bg="gray.800"  p={4} borderRadius="md">
                <StatLabel>Avg Cost Per Call</StatLabel>
                <StatNumber>SGD {dashboardData.avgCostPerCall.toFixed(2)}</StatNumber>
                <StatHelpText>Average cost per call</StatHelpText>
                </Stat>
            </GridItem>

            {/* Spend Trend */}
            <GridItem>
                <Stat bg="gray.800"  p={4} borderRadius="md">
                <StatLabel>Spend Trend</StatLabel>
                <StatNumber>
                    <StatArrow type={dashboardData.trend} />
                    {dashboardData.trend === 'increase' ? 'Increasing' : 
                    dashboardData.trend === 'decrease' ? 'Decreasing' : 'Stable'}
                </StatNumber>
                <StatHelpText>Compared to last month</StatHelpText>
                </Stat>
            </GridItem>
            <GridItem>
                <Stat bg="gray.800"  p={4} borderRadius="md" shadow="md">
                    <CallDirectionChart directionData={dashboardData.directionData} />
                </Stat>
            </GridItem>

            <GridItem colSpan={2} rowSpan={2} >
                <Stat bg="gray.800"  p={4} borderRadius="md" shadow="md">
                    <MonthlySpendChart monthlySpendData={dashboardData.monthlySpend} />
                </Stat>
            </GridItem>
          </Grid>
        )}
      </Box>
    </Flex>
  );
};

export default DashboardPage;
