import React, { useState } from 'react';
import { Box, Button, Heading, Input, VStack, Text, Link, FormControl, FormLabel } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import useCheckAuth from '../hooks/useCheckAuth';
import {Helmet} from "react-helmet";

const ForgotPasswordPage = () => {
  useCheckAuth(true);

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);  // Start loading
    setMessage('');  // Clear any previous message
    setError('');  // Clear any previous error

    try {
      const response = await axios.post('/api/forgot-password', { email });
      setMessage(response.data.message);  // Success message
      setEmail('');  // Clear email field after successful submission
    } catch (err) {
      setError('Error processing the request. Please try again.');  // Generic error message
    } finally {
      setLoading(false);  // Stop loading
    }
  };

  return (
    <>
    <Helmet>
      <title>Reset your password</title>
      <meta name="description" content="Reset your password" />
    </Helmet>
    <Box maxW="md" mx="auto" mt={8} p={6} borderWidth={1} borderRadius="lg">
      <Heading mb={6} textAlign="center">Forgot Password</Heading>
      
      {/* Success message */}
      {message && <Text color="green.500" mb={4}>{message}</Text>}

      {/* Error message */}
      {error && <Text color="red.500" mb={4}>{error}</Text>}
      
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </FormControl>

          {/* Submit button with loading state */}
          <Button type="submit" colorScheme="orange" width="full" isLoading={loading}>
            Submit
          </Button>

          <Text>
            Remembered your password?{' '}
            <Link as={RouterLink} to="/login" color="brand.500">
              Login
            </Link>
          </Text>
        </VStack>
      </form>
    </Box>
    </>
  );
};

export default ForgotPasswordPage;
