import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';
import {
    Box, Button, Input, Heading, VStack, Flex, 
    Grid, GridItem, Checkbox, InputGroup, InputRightElement,
    Tab, Tabs, TabList, TabPanels, TabPanel, useToast
  } from '@chakra-ui/react';
import axios from 'axios';
import useCheckAuth from '../hooks/useCheckAuth';
import {Helmet} from "react-helmet";

const SettingsPage = () => {
    useCheckAuth();
    
  const [formState, setFormState] = useState({});
  const [activeTab, setActiveTab] = useState(0); 

  // Individual visibility toggles for each password input field
  const [showPasswords, setShowPasswords] = useState({
    twilioAccountSid: '',
    openaiApiKey: '',
    deepgramApiKey: '',
    twilioAuthToken: '',
    cartesiaApiKey: '',
  });

  const toast = useToast();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get('/api/settings');
        setFormState(response.data); // Populate form with fetched data
      } catch (error) {
        console.error('Error fetching settings:', error);
        toast({
          title: 'Error fetching settings.',
          description: 'Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchSettings();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  // Save settings to the backend
  const handleSave = async () => {
    try {
      await axios.put('/api/settings', formState); // Save or update settings
      toast({
        title: 'Settings saved.',
        description: 'Your settings have been saved successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving settings:', error);
      toast({
        title: 'Error saving settings.',
        description: 'Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Toggle visibility of individual password inputs
  const handleTogglePasswordVisibility = (field) => {
    setShowPasswords(prevState => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  };

  return (
    <>
    <Helmet>
      <title>Enter your 3rd Party Provider API Keys</title>
      <meta name="description" content="Setting Page for entering your 3rd party provider api keys" />
    </Helmet>
    <Flex>
      {/* Navigation Sidebar */}
      <Navigation />

      {/* Main Content */}
      <Box p={8} flex="1">
        <Flex justifyContent="space-between" alignItems="center" mb={8}>
          <Heading size="lg">Settings</Heading>
          <Button onClick={handleSave}>
            Save Changes
          </Button>
        </Flex>

        <Tabs index={activeTab} onChange={(index) => setActiveTab(index)} isFitted>
          <TabList mb={4}>
            <Tab>Providers API Key</Tab>
            <Tab>API Key</Tab>
          </TabList>
          
          <TabPanels>
            {/* Providers API Key Panel */}
            <TabPanel>
              <Heading size="md" pb="4">Providers API Key</Heading>

              {/* Grid Layout */}
              <Grid templateColumns={{
                base: 'repeat(1, 1fr)',  // 1 column on small screens (mobile)
                md: 'repeat(1, 1fr)',    // 2 columns on medium screens (tablets)
                lg: 'repeat(2, 1fr)'
              }} gap={6}>
                {/* Twilio Settings */}
                <GridItem colSpan={[2, 1]}>
                  <Box bg="gray.800" p={6} borderRadius="md">
                    <Heading size="md" mb={4} color="gray.300">Twilio Settings</Heading>
                    <Grid templateColumns="1fr 1fr" gap={4}>
                      <GridItem>
                        <InputGroup size="md">
                          <Input
                            type={showPasswords.twilioAccountSid ? "text" : "password"}
                            placeholder="Twilio Account SID"
                            name="twilioAccountSid"
                            value={formState.twilioAccountSid || ''}
                            onChange={handleInputChange}
                            bg="gray.700"
                            color="gray.300"
                            pr="4.5rem"
                          />
                          <InputRightElement width="4.5rem">
                            <Button h="1.75rem" size="sm" onClick={() => handleTogglePasswordVisibility('twilioAccountSid')}>
                              {showPasswords.twilioAccountSid ? "Hide" : "Show"}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </GridItem>

                      <GridItem>
                        <InputGroup size="md">
                          <Input
                            type={showPasswords.twilioAuthToken ? "text" : "password"}
                            placeholder="Twilio Auth Token"
                            name="twilioAuthToken"
                            value={formState.twilioAuthToken || ''}
                            onChange={handleInputChange}
                            bg="gray.700"
                            color="gray.300"
                            pr="4.5rem"
                          />
                          <InputRightElement width="4.5rem">
                            <Button h="1.75rem" size="sm" onClick={() => handleTogglePasswordVisibility('twilioAuthToken')}>
                              {showPasswords.twilioAuthToken ? "Hide" : "Show"}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </GridItem>
                    </Grid>
                  </Box>
                </GridItem>

                {/* OpenAI Settings */}
                <GridItem colSpan={[2, 1]}>
                  <Box bg="gray.800" p={6} borderRadius="md">
                    <Heading size="md" mb={4} color="gray.300">OpenAI Settings</Heading>
                    <InputGroup size="md">
                        <Input
                        type={showPasswords.openaiApiKey ? "text" : "password"}
                        placeholder="OpenAI API Key"
                        name="openaiApiKey"
                        value={formState.openaiApiKey || ''}
                        onChange={handleInputChange}
                        bg="gray.700"
                        color="gray.300"
                        pr="4.5rem"
                        />
                        <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={() => handleTogglePasswordVisibility('openaiApiKey')}>
                            {showPasswords.openaiApiKey ? "Hide" : "Show"}
                        </Button>
                        </InputRightElement>
                    </InputGroup>
                  </Box>
                </GridItem>

                {/* Deepgram Settings */}
                <GridItem colSpan={[2, 1]}>
                  <Box bg="gray.800" p={6} borderRadius="md">
                    <Heading size="md" mb={4} color="gray.300">Deepgram Settings</Heading>
                    <InputGroup size="md">
                        <Input
                        type={showPasswords.deepgramApiKey ? "text" : "password"}
                        placeholder="Deepgram API Key"
                        name="deepgramApiKey"
                        value={formState.deepgramApiKey || ''}
                        onChange={handleInputChange}
                        bg="gray.700"
                        color="gray.300"
                        pr="4.5rem"
                        />
                        <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={() => handleTogglePasswordVisibility('deepgramApiKey')}>
                            {showPasswords.deepgramApiKey ? "Hide" : "Show"}
                        </Button>
                        </InputRightElement>
                    </InputGroup>
                  </Box>
                </GridItem>

                {/* Cartesia Settings */}
                <GridItem colSpan={[2, 1]}>
                  <Box bg="gray.800" p={6} borderRadius="md">
                    <Heading size="md" mb={4} color="gray.300">Cartesia Settings</Heading>
                    <InputGroup size="md">
                        <Input
                        type={showPasswords.cartesiaApiKey ? "text" : "password"}
                        placeholder="Cartesia API Key"
                        name="cartesiaApiKey"
                        value={formState.cartesiaApiKey || ''}
                        onChange={handleInputChange}
                        bg="gray.700"
                        color="gray.300"
                        pr="4.5rem"
                        />
                        <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={() => handleTogglePasswordVisibility('cartesiaApiKey')}>
                            {showPasswords.cartesiaApiKey ? "Hide" : "Show"}
                        </Button>
                        </InputRightElement>
                    </InputGroup>
                  </Box>
                </GridItem>
                {/* Domain Name */}
                <GridItem>
                  <Box bg="gray.800" p={6} borderRadius="md">
                    <Heading size="md" mb={4} color="gray.300">Domain Name. Enter without https://</Heading>
                    <InputGroup size="md">
                        <Input
                        type={"text"}
                        placeholder="Domain Name"
                        name="voiceDomainName"
                        value={formState.voiceDomainName || ''}
                        onChange={handleInputChange}
                        bg="gray.700"
                        color="gray.300"
                        />
                    </InputGroup>
                  </Box>
                </GridItem>
              </Grid>
            </TabPanel>

            {/* API Key Panel */}
            <TabPanel>
              <Heading size="md" pb="4">API Key</Heading>
              {/* Add API Key fields here */}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
    </>
  );
};

export default SettingsPage;
